import React from "react";
import { Container } from "./components/GridSystem";

const PrivacyPolicy = () => {
  return (
    <Container>
      <div className="bg-Light md:mx-10 p-5 rounded-3xl my-5">
        <h1> Privacy Policy </h1>
        Introduction Welcome to Prime Pixels Technology ("we," "our," "us"). We
        value your privacy and are committed to protecting your personal
        information. This Privacy Policy outlines how we collect, use, disclose,
        and safeguard your information when you visit our website
        www.prime-pixels.com (the "Site"). Please read this policy carefully to
        understand our views and practices regarding your personal data and how
        we will treat it.
        <br />
        <br /> Information We Collect 1. Personal Information We may collect
        personal information that you voluntarily provide to us when you:
        <br />
        <br />
        Sign up for our newsletter Contact us through the Site Make inquiries or
        request support Register for an account or create a profile This
        information may include your name, email address, phone number, and any
        other details you choose to provide.
        <br />
        <br />
        2. Non-Personal Information We may collect non-personal information
        automatically as you navigate through the Site. This may include:
        <br />
        <br />
        IP address Browser type and version Time zone setting Browser plug-in
        types and versions Operating system and platform Information about your
        visit, including the pages you view, the time spent on those pages, and
        other usage statistics. How We Use Your Information We use the
        information we collect for the following purposes:
        <br />
        <br />
        To Provide Services: To present our Site and its contents to you,
        including providing you with information, products, or services that you
        request from us. To Communicate with You: To respond to your inquiries,
        fulfill your requests, and send you newsletters or promotional
        materials. To Improve Our Site: To analyze how users interact with our
        Site, enabling us to improve the user experience and our offerings. For
        Marketing: With your consent, we may send you information about our
        services, updates, and promotions that may interest you. To Comply with
        Legal Obligations: We may use your information as required by applicable
        laws and regulations. How We Share Your Information We do not sell,
        trade, or rent your personal information to third parties. However, we
        may share your information in the following circumstances:
        <br />
        <br />
        Service Providers: We may share your information with third-party
        service providers who perform services on our behalf, such as payment
        processing, data analysis, email delivery, hosting services, customer
        service, and marketing assistance. Legal Requirements: We may disclose
        your information if required to do so by law or in response to valid
        requests by public authorities (e.g., a court or government agency).
        Business Transfers: In the event of a merger, acquisition, or sale of
        all or a portion of our assets, your personal information may be
        transferred as part of that transaction. Data Security We have
        implemented appropriate security measures to protect your personal
        information from unauthorized access, use, or disclosure. However,
        please be aware that no method of transmission over the Internet or
        electronic storage is 100% secure, and we cannot guarantee its absolute
        security.
        <br />
        <br />
        Your Rights Depending on your location, you may have certain rights
        regarding your personal information, including:
        <br />
        <br />
        Access: You can request access to the personal information we hold about
        you. Correction: You can request that we correct or update your personal
        information. Deletion: You can request that we delete your personal
        information. Opt-Out: You can opt out of receiving marketing
        communications from us by following the unsubscribe instructions
        provided in our emails. To exercise any of these rights, please contact
        us using the information provided below.
        <br />
        <br />
        Cookies and Tracking Technologies We use cookies and similar tracking
        technologies to enhance your experience on our Site. You can control the
        use of cookies at the individual browser level. If you reject cookies,
        you may still use our Site, but your ability to use some features or
        areas of our Site may be limited.
        <br />
        <br />
        Third-Party Links Our Site may contain links to third-party websites
        that are not operated by us. We have no control over and assume no
        responsibility for the content, privacy policies, or practices of any
        third-party sites or services. We encourage you to review the privacy
        policies of those third parties before providing any personal
        information.
        <br />
        <br />
        Changes to This Privacy Policy We may update this Privacy Policy from
        time to time. When we do, we will post the updated policy on this page
        and update the policy's effective date. We encourage you to review this
        Privacy Policy periodically to stay informed about how we are protecting
        your information.
        <br />
        <br />
        Contact Us If you have any questions or concerns about this Privacy
        Policy, please contact us at:
        <br />
        <br />
        Prime Pixels Technology Email: support@prime-pixels.com Website:
        www.prime-pixels.com
        <br />
        <br />
        Effective Date: 28 / Aug / 2024
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
