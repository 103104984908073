import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import next from "../../assets/images/pngwing.png";
import { Title } from "../../components";
import { Col, Container, Row } from "../../components/GridSystem";
import { iconsList } from "../../contexts/Lists";
import { content } from "../../contexts/useLang";
import { useEffect, useRef } from "react";
import Aos from "aos";

const ProductsDetails = ({ openProduct }) => {
  const targetRef = useRef();
  useEffect(() => {
    if (openProduct?.id) {
      targetRef?.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [openProduct?.id]);
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      {openProduct?.id && (
        <div ref={targetRef} className="py-5">
          <Col md={10} className="mx-auto">
            <Container>
              <div className="bg-Light rounded-3xl p-3 border border-Dark">
                <Title title={"products_" + openProduct?.id} />
                <Container>
                  <Row>
                    <Col md={6}>
                      <div className="font-bold text-xl">
                        {content.feature_description}
                      </div>
                      <ul className=" md:pl-6 pt-5 max-md:py-1 max-md:text-sm">
                        {openProduct.descs?.map((e) => (
                          <li
                            data-aos="fade-up-left"
                            data-aos-delay="50"
                            data-aos-duration="750"
                            className=" font-semibold my-2"
                          >
                            - {content?.["des_products_" + e]}
                          </li>
                        ))}
                      </ul>
                    </Col>
                    <Col md={1} />
                    <Col md={5} className="relative px-1">
                      <button className="swiper-button-prev-custom absolute left-2 max-md:-left-5 top-1/2 transform -translate-y-1/2 z-10">
                        <img src={next} alt="" className="rotate-180 w-12" />
                      </button>
                      <Swiper
                        modules={[Navigation, Autoplay, Pagination]}
                        loop={true}
                        speed={1000}
                        autoplay={{
                          delay: 3000,
                          disableOnInteraction: false,
                        }}
                        navigation={{
                          prevEl: ".swiper-button-prev-custom",
                          nextEl: ".swiper-button-next-custom",
                        }}
                        spaceBetween={5}
                        pagination={{ clickable: true }}
                      >
                        {openProduct.images?.map((e) => (
                          <SwiperSlide className="pb-10">
                            <div className="p-3 overflow-hidden flex justify-center items-center h-[320px] w-[300px] rounded-2xl border border-Dark bg-Light mx-auto">
                              <img
                                className="object-contain"
                                src={e.image}
                                alt=""
                              />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <button className="swiper-button-next-custom absolute right-2 max-md:-right-5 top-1/2 transform -translate-y-1/2 z-10">
                        <img src={next} alt="" className=" w-12" />
                      </button>
                    </Col>
                  </Row>
                </Container>
                <div>
                  <div className="flex justify-center items-center  md:gap-6 gap-1">
                    {openProduct.icons?.map((e, i) => (
                      <div className=" flex flex-col justify-around shadow-md bg-Light hover:bg-Main h-[6rem] md:w-[5.5rem] w-[4rem] text-center space-y-2 md:p-2 p-1 rounded-xl border border-Dark hover:!-translate-y-1 transition-all">
                        <div>
                          <img
                            src={iconsList?.["icon_" + e]}
                            alt=""
                            className=" w-6 md:w-9 mx-auto"
                          />
                        </div>
                        <div className=" md:text-xs text-[10px]">
                          {
                            content?.[
                              "title_icon_" + openProduct?.title_icons?.[i]
                            ]
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </div>
      )}
    </>
  );
};

export default ProductsDetails;
