import React, { useEffect } from "react";
import { BtnHover, Title } from "../../components";
import { Col, Container, Row } from "../../components/GridSystem";
import { content } from "../../contexts/useLang";
import { productList } from "../../contexts/Lists";
import Aos from "aos";
import whatsapp from "../../assets/images/whatsappWithGirl.webp";
const Products = ({ setOpenProduct, openProduct }) => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div>
      <div className="mb-0 pb-0 mt-14 relative">
        <Title title="products" />
      </div>

      <Container>
        <Row className="">
          {productList
            .filter((e) => e.id !== openProduct.id)
            .map((e, i) => (
              <Col sm={6} md={4} className="!py-2 max-md:p-1 !mt-0 !pt-0">
                <div
                  onClick={() => {
                    setOpenProduct(e);
                  }}
                  data-aos="zoom-in-left"
                  data-aos-delay="50"
                  data-aos-duration="750"
                  className="bg-Light rounded-3xl md:p-3 border border-Dark shadow-lg hover:shadow-2xl hover:!scale-105 hover:-translate-y-1 transition-all"
                >
                  <div className=" flex justify-center items-center h-[150px] w-[120px] md:h-[300px] md:w-[260px] mx-auto">
                    <img
                      src={e.image}
                      alt="contact_us"
                      className={
                        e.id === 7 ? "w-full h-full" : "object-contain"
                      }
                    />
                  </div>
                  <BtnHover name={content["products_" + e.id]} />
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default Products;
