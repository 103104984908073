import lang from "./lang.json";

export let content = {};
export const langDiv = navigator.language || navigator.userLanguage;
if (
  (localStorage.getItem("language") === "ar" &&
    localStorage.getItem("language")) ||
  (langDiv === "ar" && !localStorage.getItem("language"))
) {
  content = lang.validate.ar;
} else {
  content = lang.validate.en;
}
