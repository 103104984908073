import AOS from "aos";
import { content } from "../contexts/useLang";
import { useEffect } from "react";

const Title = ({ title }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div
      data-aos="zoom-in"
      data-aos-delay="50"
      data-aos-duration="500"
      // className="main-title"
      className="bg-Light hover:bg-Secondary transition-all py-1 hover:text-Light w-fit mx-auto border border-Dark rounded-full px-3 font-semibold mt-3 mb-1"
    >
      {content[title]}
    </div>
  );
};

export default Title;
