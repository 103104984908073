export const Col = ({ md, lg, sm, xl, xs, col, children, className }) => {
  return (
    <div
      className={`
      ${col ? `col-${col} ` : ""}
      ${lg ? `col-lg-${lg} ` : ""}
      ${md ? `col-md-${md} ` : ""}
      ${xs ? `col-xs-${xs} ` : ""}
      ${sm ? `col-sm-${sm} ` : ""}
      ${xl ? `col-xl-${xl} ` : ""}
      ${className ? `${className} ` : ""}
      `}
    >
      {children}
    </div>
  );
};
export const Row = ({ children, className, gap, justify }) => {
  return (
    <div
      className={`row px-2
         ${gap ? "gap-" + gap : ""}
         ${justify ? "justify-" + justify : ""}
         ${className ? `${className} ` : ""}`}
    >
      {children}
    </div>
  );
};
export const Container = ({ children }) => {
  return (
    <section className="">
      <div className="w-[94%] max-lg:w-full max-sm:w-[98%] mx-auto">
        {children}
      </div>
    </section>
  );
};
