import React, { useEffect } from "react";
import { BtnHover, Title } from "../../components";
import { Col, Row } from "../../components/GridSystem";
import { content } from "../../contexts/useLang";
import { servicesList } from "../../contexts/Lists";
import Aos from "aos";

const Services = ({ setTarget }) => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="mt-14">
      <Title title="services" />
      <Row justify="center">
        {servicesList.map((e, i) => (
          <Col sm={6} md={5} className="!py-2 max-md:p-1">
            <div
              data-aos="zoom-out-right"
              data-aos-delay="50"
              data-aos-duration="750"
              onClick={() => setTarget("contact_us")}
              className="bg-Light rounded-3xl md:p-3 border border-Dark shadow-lg hover:shadow-2xl transition-all"
            >
              <div className=" flex justify-center items-center h-[150px] w-[120px] md:h-[260px] md:w-[300px] mx-auto">
                <img
                  src={e.image}
                  alt="contact_us"
                  className="object-contain "
                />
              </div>
              <BtnHover name={content["services_" + (i + 1)]} />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Services;
