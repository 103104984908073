function BtnHover({ onClick, name }) {
  return (
    <button
      onClick={onClick}
      className={`md:px-5 hoverMain border border-Secondary group bg-white w-fit my-4 py-1 rounded-full flex justify-center mx-auto text-sm text-Secondary font-semibold after:bg-Secondary relative overflow-hidden`}
    >
      <div className="relative px-2 py-1 group-hover:z-10">
        <span>{name}</span>
      </div>
    </button>
  );
}
export default BtnHover;
