import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA_ycrnbo-y3OzN80d9Q3-w_qoZHZb4ngQ",
  authDomain: "primepixels-28767.firebaseapp.com",
  projectId: "primepixels-28767",
  storageBucket: "primepixels-28767.appspot.com",
  messagingSenderId: "1073085111229",
  appId: "1:1073085111229:web:07f36188db55a0140a50d4",
  measurementId: "G-8S9B6BNRHV",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
