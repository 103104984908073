import Aos from "aos";
import React, { useEffect, useState } from "react";
import email from "../../assets/images/email.webp";
import locationIcon from "../../assets/images/locationIcon.webp";
import logo from "../../assets/images/1.png";
import whatsappIcon from "../../assets/images/whatsappIcon.webp";
import { Title } from "../../components";
import { Col, Container, Row } from "../../components/GridSystem";
import { ContactList } from "../../contexts/Lists";
import { content } from "../../contexts/useLang";
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    product: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // إضافة المستند إلى المجموعة "messages"
      await addDoc(collection(db, "messages"), formData);
      alert("تم إرسال النموذج بنجاح!");
      setFormData({ name: "", phone: "", email: "", product: "" });
    } catch (error) {
      console.error("حدث خطأ عند الإرسال: ", error);
    }
  };
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="mt-14">
      <Container>
        <Title title="contact_us" />
        <div className=" border border-Dark rounded-3xl px-8 py-4  mx-auto bg-Light ">
          <div className="flex items-center gap-2 md:hidden">
            <div>
              <img src={logo} alt="" className="md:w-24 w-20" />
            </div>
            <div className="font-semibold md:text-xl">
              {content.prime_pixels_technology}
            </div>
          </div>
          <Row className="flex  justify-between">
            <Col md={6} className="space-y-4 max-md:order-2 pt-5">
              <div className="flex items-center gap-2 max-md:hidden">
                <div>
                  <img src={logo} alt="" className="md:w-24 w-20" />
                </div>
                <div className="font-semibold md:text-xl">
                  {content.prime_pixels_technology}
                </div>
              </div>
              <div className="flex items-center gap-5">
                <div>
                  <img src={locationIcon} alt="" className="w-10" />
                </div>
                <div
                  data-aos="fade-up-left"
                  data-aos-delay="50"
                  data-aos-duration="750"
                >
                  {content.address_1}
                  <br />
                  {content.address_2}
                </div>
              </div>
              <div
                data-aos="fade-up-left"
                data-aos-delay="50"
                data-aos-duration="750"
                className="flex items-center gap-5"
              >
                <div>
                  <img src={whatsappIcon} alt="" className="w-10" />
                </div>
                <div>
                  {content.phone_number_1} <br />
                  {content.phone_number_2}
                </div>
              </div>
              <div
                data-aos="fade-up-left"
                data-aos-delay="50"
                data-aos-duration="750"
                className="flex items-center gap-5"
              >
                <div>
                  <img src={email} alt="" className="w-10" />
                </div>
                <div> {content.email_1}</div>
              </div>
            </Col>
            <Col md={6} className="max-md:order-1">
              <div>
                <div>{content.name}:</div>
                <input
                  onChange={handleChange}
                  value={formData.name}
                  name="name"
                  type="text"
                  className="border border-Dark my-1 md:w-3/4 w-full py-1 rounded-xl"
                />
              </div>
              <div>
                <div>{content.phone_number}:</div>
                <input
                  onChange={handleChange}
                  value={formData.phone}
                  name="phone"
                  type="text"
                  className="border border-Dark my-1 md:w-3/4 w-full py-1 rounded-xl"
                />
              </div>
              <div>
                <div>{content.email}:</div>
                <input
                  onChange={handleChange}
                  value={formData.email}
                  name="email"
                  type="text"
                  className="border border-Dark my-1 md:w-3/4 w-full py-1 rounded-xl"
                />
              </div>
              <div>
                <div>{content.product_you_are_interested}:</div>
                <select
                  onChange={handleChange}
                  value={formData.product}
                  name="product"
                  type="text"
                  className="border border-Dark my-1 md:w-3/4 w-full py-1 rounded-xl"
                >
                  <option value="" hidden></option>
                  {[1, 2, 3, 4, 5, 6, 7].map((e) => (
                    <option value={content["products_" + e]}>
                      {content["products_" + e]}
                    </option>
                  ))}
                </select>
              </div>
              <div className="md:w-3/4 mt-2">
                <button
                  onClick={handleSubmit}
                  className={`md:px-5 hoverMain border border-Secondary group bg-white w-fit py-1 rounded-xl flex justify-center mx-auto text-sm text-Secondary font-semibold after:bg-Secondary relative overflow-hidden`}
                >
                  <div className="relative px-2 py-1 group-hover:z-10">
                    <span>{content.inquiry_send}</span>
                  </div>
                </button>
              </div>
            </Col>
          </Row>
          <div>
            <Title title="quick_links" />
            <div className="flex justify-around items-center flex-wrap gap-5">
              {ContactList.map((e) => (
                <div
                  className="image-container"
                  data-aos="zoom-out-up"
                  data-aos-delay="50"
                  data-aos-duration="750"
                >
                  <a href={e.link} target="_blank">
                    <img
                      src={e.image}
                      alt="contact_us"
                      className="md:w-20 w-14 "
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="text-center mt-12 text-sm">
            All rights reserved to prime pixels trading Company
            <br />
            Dubai - UAE
          </div>

          <div className="text-center text-Secondary">
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactUs;
